@tailwind base;
@tailwind components;
@tailwind utilities;

.icon-hover-gradient:hover {
  stroke: url(#codecosmos-gradient);
}

.icon-hover-gradient:hover svg {
  stroke: url(#codecosmos-gradient);
}

.hover-gradient-bg:hover,
.gradient-bg {
  background: linear-gradient(
    140deg,
    rgba(11, 237, 242, 1) 0%,
    rgba(130, 150, 248, 1) 50%,
    rgba(251, 62, 255, 1) 100%
  );
}

.gradient-text {
  background: linear-gradient(
    140deg,
    rgba(11, 237, 242, 1) 0%,
    rgba(130, 150, 248, 1) 50%,
    rgba(251, 62, 255, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

a.animated-underline {
  cursor: pointer;
  position: relative;
}

a.animated-underline::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #0bedf2, #fb3eff);
  opacity: 1;
  transition: all 300ms;
}

a.animated-underline:hover::after,
a.animated-underline:focus::after {
  opacity: 0;
  width: 0%;
}

a.animated-underline:hover::after,
a.animated-underline:focus::after {
  transform: translate3d(0, 0, 0);
}

header {
  min-height: 90vh;
  background: url('./assets/header.jpg') center center no-repeat;
  background-size: cover;
  position: relative;

  > div {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #000;
    opacity: 0.7;
    z-index: 1
  }
}

.tracking-wide-2 {
  letter-spacing: .2em;
}

.price1 {
  background: url('/assets/prijs1.jpeg') center center no-repeat;
  background-size: cover;
  min-height: 200px;
}

.price2 {
  background: url('/assets/prijs2.jpg') center center no-repeat;
  background-size: cover;
  min-height: 200px;
}